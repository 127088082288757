<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="region" :translate-params="{id: region.id, name: region.name}">Edit Region  #%{id} - %{name}</translate>
    </h1>
    <Form :data=region @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/region/region/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditRegion',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchRegion(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'region.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('region', [
      'region',
    ]),
  },
  methods: {
    ...mapActions('region', [
      'fetchRegion',
      'updateRegion',
    ]),
    async onSave(data) {
      await this.updateRegion({id: this.region.id,data: data})
      await this.$router.push({name: 'region.list'})
      throw new SuccessNotification(this.$pgettext('region', 'Region updated!'))
    }
  }
}
</script>